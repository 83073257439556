import {Model} from './base/Model';
import Collection from "./base/Collection";
import {decorate, computed} from 'mobx';
import {Organization} from './OrganizationsStore';

export class Expense extends Model {
    urlRoot() {
        return "/expenses/";
    }

    get supplier() {
    	const data = new Organization({id: this.get('supplier')})
        data.fetch()
        return data
    }

}

decorate(Expense, {
    supplier: computed
})


export class Expenses extends Collection {

    urlRoot() {
        return "/expenses/";
    }

    model() {
        return Expense;
    }
}

import React, {Component} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

class Filter extends Component {

    handle = event => {
        this.props.setfilter(event.target.value);
    }

    render() {
        return (
            <div>
              <FormControl style={{width: "150px"}}>
                <InputLabel>Ignore</InputLabel>
                <Select name="ignore" onChange={this.handle} value={this.props.ignore}>
                  <MenuItem value="">ALL</MenuItem>
                  <MenuItem value="1">Ignored</MenuItem>
                  <MenuItem value="0">Not Ignored</MenuItem>
                </Select>
              </FormControl>
            </div>
        );
    }
}

export default Filter;

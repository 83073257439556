import React, {Component} from 'react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import authStore from "../../services/AuthStore.js";
import {Expenses as ExpensesCollection} from "../../services/ExpensesStore";
import CircularProgress from '@material-ui/core/CircularProgress';
import {observer} from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Suppliers} from '../../services/OrganizationsStore';
import YearFilter from '../InvoicesList/YearFilter';
import OrganizationFilter from '../OrganizationFilter/OrganizationFilter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateFilter from '../DateFilter/DateFilter';
import Filter from '../InvoicesList/Filter';

import styles from './ExpensesList.module.scss';

const rawValueReducer = (accumulator, expense) => accumulator + parseFloat(expense.get('raw_value'));
const taxReducer = (accumulator, expense) => accumulator + parseFloat(expense.get('tax'));
const valueReducer = (accumulator, expense) => accumulator + parseFloat(expense.get('value'));

const Expenses = props => {
    let {expenses, destroy, edit} = props;
    return (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="left">Supplier</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="right">Raw</TableCell>
                <TableCell align="right">Tax</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell align="center">Ignored</TableCell>
                <TableCell align="center">Paid At</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenses.models.map(
                  expense =>
                      <ExpenseLine key={expense.get('id')} expense={expense} edit={edit} destroy={destroy}/>)
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">{expenses.models.reduce(rawValueReducer, 0).toFixed(2)}</TableCell>
                <TableCell align="right">{expenses.models.reduce(taxReducer, 0).toFixed(2)}</TableCell>
                <TableCell align="right">{expenses.models.reduce(valueReducer, 0).toFixed(2)}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </>
    );
};


const ExpenseForm = observer(class ExpensesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
            mode: 'add'
        };
    }

    render() {
        const {expense, change, submit, open, close, mode} = this.props;
        const title = mode === 'add' ? 'Create Expense' : 'Edit Expense';
        const action = mode === 'add' ? 'Submit' : 'Update';
        return (
            <Dialog
              open={open}
              onClose={()=> console.log('handle close')}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {title}
              </DialogTitle>
              <DialogContent>

                <FormControl fullWidth>
                  <InputLabel htmlFor="supplier">Supplier</InputLabel>
                  <Select
                    value={expense.get('supplier') || ''}
                    name="supplier"
                    onChange={change}
                    input={<Input id="age-simple" />}
                    id="supplier"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Suppliers.models.map(supplier => <MenuItem key={supplier.id} value={supplier.id}>{supplier.get('name')}</MenuItem>)}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel htmlFor="supplier">Ignore Expense</InputLabel>
                  <Select
                    value={expense.get('ignore')}
                    name="ignore"
                    onChange={change}
                    input={<Input id="age-simple" />}
                    id="ignore"
                  >
                    <MenuItem value="false">
                      <em>Do Not Ignore</em>
                    </MenuItem>
                    <MenuItem value="true">
                      <em>Ignore</em>
                    </MenuItem>
                  </Select>
                </FormControl>


                <TextField
                  margin="dense"
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  value={expense.get('description')}
                  onChange={change}
                />

                <TextField
                  margin="dense"
                  id="raw_value"
                  name="raw_value"
                  label="Raw Value"
                  type="number"
                  fullWidth
                  value={expense.get('raw_value')}
                  onChange={change}
                />

                <TextField
                  margin="dense"
                  id="tax"
                  name="tax"
                  label="Tax"
                  type="number"
                  fullWidth
                  value={expense.get('tax')}
                  onChange={change}
                />

                <TextField
                  margin="dense"
                  id="value"
                  name="value"
                  label="Value"
                  type="number"
                  fullWidth
                  value={expense.get('value')}
                  onChange={change}
                />

                <TextField
                  id="date"
                  label="Paid At"
                  type="date"
                  name="paid_at"
                  value={expense.get('paid_at')}
                  onChange={change}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={submit} color="primary">
                  {action}
                </Button>
                <Button onClick={close} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
        );
    }

});

const ExpenseLine = observer(props => (
    <TableRow key={props.expense.id}>
      <TableCell align="center">{props.expense.get('id')}</TableCell>
      <TableCell align="left">{props.expense.supplier.get('name')}</TableCell>
      <TableCell align="left">{props.expense.get('description')}</TableCell>
      <TableCell align="right">{props.expense.get('raw_value')}</TableCell>
      <TableCell align="right">{props.expense.get('tax')}</TableCell>
      <TableCell align="right">{props.expense.get('value')}</TableCell>
      <TableCell align="center">{props.expense.get('ignore') ? 'x' : ''}</TableCell>
      <TableCell align="center">{props.expense.get('paid_at')}</TableCell>
      <TableCell align="center">
        <Button color="primary" onClick={() => props.edit(props.expense)}>
          <Tooltip title="edit expense" aria-label="edit expense">
            <SvgIcon>
              <EditIcon className={styles.Issued}/>
            </SvgIcon>
          </Tooltip>
        </Button>

        <Button color="primary" onClick={() => props.destroy(props.expense)}>
          <Tooltip title="delete expense" aria-label="delete expense">
            <SvgIcon>
              <DeleteIcon className={styles.Issued}/>
            </SvgIcon>
          </Tooltip>
        </Button>
      </TableCell>
    </TableRow>
));

const ExpensesList = observer(class ExpensesList extends Component {

    filters = {
        page_size: 10000,
        year: new Date().getFullYear()
    }

    state = {
        open: false,
        mode: 'add'
    }

    constructor(props) {
        super(props);
        this.expenses = new ExpensesCollection();
        this.expense = this.expenses.build();
    }

    componentWillMount() {
        if (!authStore.user) {
            authStore.setRedirectUrl(this.props.location.pathname);
            this.props.history.push('/login');
        }
    }

    _refresh = () => {
        this.expenses.fetch(this.filters);
    }

    componentDidMount() {
        this._refresh();
    }

    handleFilter = year => {
        this.filters['year'] = year;
        this._refresh();
    }

    handleIgnoreFilter = value => {
        this.filters['ignore'] = value;
        this._refresh();
    }


    handleChange = value => {
        this.filters['supplier'] = value;
        this._refresh();
    }

    handleInputChange = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.expense.set({
            [name]: value
        });
    }

    handleSubmit = event => {
        this.expense.save().then(() => this._refresh());
        this.setState({open: false});
    }

    handleDate = (field, date) => {
        this.filters[field] = date;
        this._refresh();
    }

    handleEdit = expense => {
        this.expense = expense;
        this.setState({open: true, mode: 'edit'});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleDestroy = expense => {
        expense.destroy().then(this._refresh);
    }

    handleAdd = event => {
        this.expense = this.expenses.build();
        this.setState({open: true, mode: 'add'});
    }

    render() {
        if(this.expenses.request) {
            return <CircularProgress color="secondary" />;
        }
        const start = 'paid_at__gte';
        const end = 'paid_at__lte';
        return (
            <div>
              <OrganizationFilter options={Suppliers} changed={this.handleChange} selected={this.filters.supplier}/>
              <YearFilter setfilter={this.handleFilter} year={this.filters.year}/>
              <DateFilter changed={date => this.handleDate(start, date)} selected={this.filters[start]} label="start"/>
              <DateFilter changed={date => this.handleDate(end, date)} selected={this.filters[end]} label="end"/>
              <Filter setfilter={this.handleIgnoreFilter} ignore={this.filters.ignore}/>
              <Expenses expenses={this.expenses} page_size={this.filters.page_size} edit={this.handleEdit} destroy={this.handleDestroy}/>
              <Button onClick={this.handleAdd}>Add Expense</Button>
              <ExpenseForm mode={this.state.mode} open={this.state.open} close={this.handleClose} expense={this.expense} change={this.handleInputChange} submit={this.handleSubmit}/>
            </div>
        );
    }
});

export default ExpensesList;

import React, {Component} from 'react';
// import {observer} from 'mobx-react';
import './AuthView.css';
// import styles from './Login.module.css';
// import MainHeader from "../MainHeader/MainHeader";
// import Cover from "../../../assets/images/home_cover.jpeg";
// import {Fader} from "../Fader/Fader";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
// import {Column, Row} from "../FlexLayout/FlexLayout";
// import $ from "../../../services/TranslationsStore";
import {Link} from "react-router-dom";
import {User} from "../../services/UsersStore";
// import {withSnackbar} from 'material-ui-snackbar-provider';
// import CountriesList from "../../../services/CountriesStore";


// @observer
class SignUpView extends Component {
    constructor(props) {
        super(props);


        this.state = {
            searchCountryCodeValue: ''
        }
    }

    componentWillMount() {
        this.user = new User({
            'cellphone': '',
            'cellphone_calling_code': ''
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : (target.type === 'date') ? new Date(target.value) : target.value;
        const name = target.name;

        this.user.set({
            [name]: value
        });
    }

    handleSubmit = () => {
        this.user.save().then(user => {
            if (this.user.get('id')) {
                this.props.snackbar.showMessage('Your account has been created!');
                this.props.history.push('/sign-in');
            }
        })
    }

    render() {
        return (
            <div className='sign-in-view auth-view'>

                    <Card className='auth-card'>
                        <CardContent className='header'>
                                <h1 className='main-title'>{'Sign-up now'}</h1>

                                <TextField
                                    name="name"
                                    label={'Name'}
                                    value={this.user.get('name')}
                                    margin='dense'
                                    type='text'
                                    onChange={this.handleInputChange}
                                    error={this.user.errors.get('name')}
                                    helperText={this.user.errors.get('name')}
                                />


                                    <TextField
                                        name="cellphone_calling_code"
                                        label={'Ext.'}
                                        value={this.user.get('cellphone_calling_code')}
                                        onChange={this.handleInputChange}
                                        margin='dense'
                                        select
                                        style={{minWidth: 70}}
                                        error={this.user.errors.get('cellphone_calling_code')}
                                        helperText={this.user.errors.get('cellphone_calling_code')}
                                    >
                                        <MenuItem value="" style={{padding: 0, height: 'auto'}}>
                                            <Input
                                                placeholder="Search"
                                                onClick={(e) => e.stopPropagation()}
                                                inputRef={(ref) => this.countryCodeSearchInput = ref}
                                                style={{width: '100%', padding: '10px 15px', border: '0'}}
                                                onChange={(e) => this.setState({searchCountryCodeValue: e.target.value.toLowerCase()})}
                                            />
                                        </MenuItem>
                                        {this.renderCountryExtensionOptions()}
                                    </TextField>
                                    <TextField
                                        name="cellphone"
                                        label={'Cellphone'}
                                        value={this.user.get('cellphone')}
                                        margin='dense'
                                        type='number'
                                        onChange={this.handleInputChange}
                                        error={this.user.errors.get('cellphone')}
                                        helperText={this.user.errors.get('cellphone')}
                                        style={{width: '100%'}}
                                    />

                                <TextField
                                    name="email"
                                    label="Email"
                                    value={this.user.get('email')}
                                    margin='dense'
                                    type='email'
                                    onChange={this.handleInputChange}
                                    error={this.user.errors.get('email')}
                                    helperText={this.user.errors.get('email')}
                                />

                                <TextField
                                    name="password"
                                    label={'Password'}
                                    value={this.user.get('password')}
                                    margin='dense'
                                    type='password'
                                    onChange={this.handleInputChange}
                                    error={this.user.errors.get('password')}
                                    helperText={this.user.errors.get('password')}
                                />

                                <Button color='primary' variant='raised' className='submit-btn primary-btn'
                                        onClick={this.handleSubmit}>
                                    {'Sign up'}
                                </Button>
                                {this.user.errors.get('non_field_errors') &&
                                <small style={{color: 'red', textAlign: 'center', width: '100%', paddingTop: 5}}>
                                    {this.user.errors.get('non_field_errors')}
                                </small>
                                }

                                <small style={{textAlign: 'center', color: '#666', opacity: '0.8', marginTop: '20px'}}>
                                    {'By signing-up you are agreeing with our'}<br/>
                                    <Link to='/terms-and-conditions' target='_blank'>{'terms and conditions'}</Link>
                                    &nbsp;{'and'}&nbsp;
                                    <Link to='/privacy-policy' target='_blank'>{'privacy policy'}</Link>
                                </small>
                        </CardContent>


                            <Link to='/sign-in'>
                                <small>{'Already have an account? Sign in here'}</small>
                            </Link>

                    </Card>
            </div>
        );
    }

    // renderCountryExtensionOptions() {
    //     const countries = (this.state.searchCountryCodeValue) ?
    //         CountriesList.models.filter(this._countryCodeFilter)
    //         : CountriesList.models

    //     return countries.map(country => <MenuItem
    //         key={country.get('id')}
    //         value={country.get('calling_code')}>
    //         {'(+' + country.get('calling_code') + ')'}
    //         <span className='hide-it' style={{paddingLeft: 5}}>{country.get('name')}</span>
    //     </MenuItem>)
    // }

    // _countryCodeFilter = (c) => {
    //     const name = c.get('name') + ""
    //     const cc = c.get('calling_code') + ""
    //     return (
    //         name.toLowerCase().indexOf(this.state.searchCountryCodeValue) !== -1 ||
    //         cc.toLowerCase().indexOf(this.state.searchCountryCodeValue) !== -1
    //     );
    // }
}

// export default withSnackbar()(SignUpView)
export default SignUpView;

import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';

class DateFilter extends Component {

    handle = event => {
        this.props.changed(event.target.value);
    }

    render() {
        return (
            <div>
              <TextField
                id="date"
                label={this.props.label}
                type="date"
                onChange={this.handle}
                value={this.props.selected}
                InputLabelProps={{
                    shrink: true,
                }}
              />
            </div>
        );
    }
}

export default DateFilter;

import {Model} from './base/Model';
import Collection from "./base/Collection";

export class Organization extends Model {
    urlRoot() {
        return "/organizations/";
    }
}

export class Organizations extends Collection {

    urlRoot() {
        return "/organizations/";
    }

    model() {
        return Organization;
    }
}

const Clients = new Organizations()
Clients.fetch({is_client: true})
export {Clients}

const Suppliers = new Organizations()
Suppliers.fetch({is_supplier: true})
export {Suppliers}

import {LocalConfigs} from './LocalConfigs.js'

// retorna uma array onde os elementos começam no número
// definido por start (inclusive) e vão até ao número end (inclusive)
// ver you dont need loadash
export function range(start, end) {
    const arrayLength  = end + 1 - start
    return [...Array(arrayLength).keys()].map(k => start + k)
}

export function getStartYear() {
    return LocalConfigs.START_YEAR
}

export function getCurrentYear() {
    return new Date().getFullYear();
}

export function getYearsList() {
    const startYear = getStartYear()
    const currentYear = getCurrentYear()
    return range(startYear, currentYear)
}

import React, {Component} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {getYearsList} from '../../services/Utils.js';

class YearFilter extends Component {

    handle = event => {
        this.props.setfilter(event.target.value);
    }

    render() {
        return (
            <div>
              <FormControl style={{width: "150px"}}>
                <InputLabel>Year</InputLabel>
                <Select name="boat_type" onChange={this.handle} value={this.props.year}>
                  {getYearsList().map(year => <MenuItem value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
        );
    }
}

export default YearFilter;

import React, {Component} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

class OrganizationFilter extends Component {

    handle = event => {
        this.props.changed(event.target.value);
    }

    render() {
        return (
            <div>
              <FormControl style={{width: "150px"}}>
                <InputLabel>Organization</InputLabel>
                <Select name="boat_type" onChange={this.handle} value={this.props.selected}>
                  <MenuItem value=''>
                    <em>ALL</em>
                  </MenuItem>
                  {this.props.options.models.map(
                      option =>
                          <MenuItem key={option.get('id')} value={option.get('id')}>
                            {option.get('name')}
                          </MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>
        );
    }
}

export default OrganizationFilter;

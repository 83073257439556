// import {observable, action} from 'mobx';

import {User} from './UsersStore'
import API from './base/Api'

class AuthStore {
    // @observable token = null
    // @observable user = null
    // @observable loginRequest = null
    redirectUrl = null


    constructor() {
        this._setToken(localStorage.getItem('auth.token'))
        this.redirectUrl = localStorage.getItem('auth.redirectUrl')
        this._getUserData()
    }

    // @action
    async hydrateComplete() {
        if (this.token) {
            API.setHeader('Authorization', 'Bearer ' + this.token)
            this.user = await this._getUserData()
            if (this.user == null) {
                this.logout()
            }
        }
    }

    setRedirectUrl(url) {
        this.redirectUrl = url
        localStorage.setItem('auth.redirectUrl', url)
    }

    _getUserData() {
        let user = new User({id: 'me'});
        let response = user.fetch()
        response.then(resp => {
            if (resp.response.ok) {
                this.user = user;
                return user
            } else {
                return null;
            }
        })
        return response
    }

    // @action
    login(email, password) {
        this.loginRequest = API.post('/api-token-auth/', {email, password})

        this.loginRequest.then(
            result => {
                if (result.ok) {
                    this.loginRequest = null;
                    return this.setLogeedInByToken(result.data.token)
                }
                this.loginRequest = null;
            },
            error => this.loginRequest = null
        )

        return this.loginRequest
    }

    // @action
    setLogeedInByToken(token) {
        this._setToken(token)
        return this._getUserData()
    }

    // @action
    logout() {
        this._setToken(null)
        this.user = null;
    }

    _setToken(token) {
        this.token = token
        localStorage.setItem('auth.token', token)
        if (token && token !== 'null') {
            API.setHeader('Authorization', 'Bearer ' + this.token)
        } else {
            API.setHeader('Authorization', null)
            delete API.headers['Authorization']
        }
    }
}

const authStore = new AuthStore();

export default authStore;

import {Model} from './base/Model'
import Collection from "./base/Collection"
// import {computed} from "mobx/lib/mobx";
// import Api from "./base/Api";

export class User extends Model {
    urlRoot() {
        return "/users/"
    }

    // @computed
    // get avatarFullPath() {
    //     return Api.getBaseURL() + '/static/media/' + this.get('avatar')
    // }
}

export class Users extends Collection {
    urlRoot() {
        return "/users/"
    }

    model() {
        return User
    }
}

import React, {Component} from 'react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import authStore from "../../services/AuthStore.js";
import {Invoices as InvoicesCollection} from "../../services/InvoicesStore.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import {observer} from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CommentIcon from '@material-ui/icons/Comment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Clients} from '../../services/OrganizationsStore';
import moment from 'moment/moment.js';
import YearFilter from './YearFilter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {getYearsList} from '../../services/Utils.js';

import styles from './InvoicesList.module.scss';

const valueReducer = (accumulator, invoice) => accumulator + parseFloat(invoice.get('value'));
const totalReducer = (accumulator, invoice) => accumulator + parseFloat(invoice.get('total'));

const Invoices = props => {
    let {invoices, destroy, edit, paid, issue, send} = props;
    return (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">Year</TableCell>
                <TableCell align="left">Client</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="center">Paid</TableCell>
                <TableCell align="center">Issued At</TableCell>
                <TableCell align="center">Sent At</TableCell>
                <TableCell align="center">Paid At</TableCell>
                <TableCell align="right">Observations</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.models.map(invoice => <InvoiceLine key={invoice.id} invoice={invoice} destroy={destroy} edit={edit} paid={paid} issue={issue} send={send}/>)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">{invoices.models.reduce(valueReducer, 0).toFixed(2)}</TableCell>
                <TableCell align="right">{invoices.models.reduce(totalReducer, 0).toFixed(2)}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </>
    );
};

const StateField = props => {
    const classes = props.state === 'PAID' ? styles.Paid : styles.Issued;
    return (
        <Button onClick={props.mark}>
          <SvgIcon>
            <CheckIcon className={classes}/>
          </SvgIcon>
        </Button>
    );
};

const ObservationsField = props => {
    if(!props.data) {
        return null;
    }
    return (
        <Tooltip title={props.data} aria-label={props.data}>
          <SvgIcon>
            <CommentIcon className={styles.Issued}/>
          </SvgIcon>
        </Tooltip>
    );
};

const IssuedAt = props => {
    const {invoice, issue} = props;
    if(invoice.get('issued_at')) {
        return invoice.get('issued_at');
    }
    return (
        <Button color="primary" onClick={() => issue(invoice)}>
          <Tooltip title="ask accounting to issue invoice" aria-label="ask accounting to issue invoice">
            <SvgIcon>
              <PlaylistAddIcon className={styles.Issued}/>
            </SvgIcon>
          </Tooltip>
        </Button>
    );
};

const SentAt = props => {
    const {invoice, send} = props;
    if(invoice.get('sent_at')) {
        return invoice.get('sent_at');
    }
    return (
        <Button color="primary" onClick={() => send(invoice)}>
          <Tooltip title="send invoice to client" aria-label="send invoice to client">
            <SvgIcon>
              <MailOutlineIcon className={styles.Issued}/>
            </SvgIcon>
          </Tooltip>
        </Button>
    );
};


const InvoiceLine = observer(props => (
    <TableRow key={props.invoice.id}>
      <TableCell align="center">{props.invoice.get('invoice_id')}</TableCell>
      <TableCell>{props.invoice.get('year')}</TableCell>
      <TableCell align="left">{props.invoice.client.get('name')}</TableCell>
      <TableCell align="left">{props.invoice.get('description')}</TableCell>
      <TableCell align="right">{props.invoice.get('value')}</TableCell>
      <TableCell align="right">{props.invoice.get('total')}</TableCell>
      <TableCell align="center">
        <StateField state={props.invoice.get('state')} mark={() => props.paid(props.invoice)}/>
      </TableCell>
      <TableCell align="center">
        <IssuedAt {...props}/>
      </TableCell>
      <TableCell align="center">
        <SentAt {...props}/>
      </TableCell>
      <TableCell align="center">{props.invoice.get('paid_at')}</TableCell>
      <TableCell align="right">
        <ObservationsField data={props.invoice.get('observations')}/>
      </TableCell>
      <TableCell align="center">

        <Button color="primary" onClick={() => props.edit(props.invoice)}>
          <Tooltip title="edit invoice" aria-label="edit invoice">
            <SvgIcon>
              <EditIcon className={styles.Issued}/>
            </SvgIcon>
          </Tooltip>
        </Button>

        <Button color="primary" onClick={() => props.destroy(props.invoice)}>
          <Tooltip title="delete invoice" aria-label="delete invoice">
            <SvgIcon>
              <DeleteIcon className={styles.Issued}/>
            </SvgIcon>
          </Tooltip>
        </Button>
      </TableCell>
    </TableRow>
));

const InvoiceForm = observer(class InvoicesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
            mode: 'add'
        };
    }

    render() {
        const {invoice, change, submit, open, close, mode} = this.props;
        const title = mode === 'add' ? 'Create Invoice' : 'Edit Invoice';
        const action = mode === 'add' ? 'Submit' : 'Update';
        return (
            <Dialog
              open={open}
              onClose={()=> console.log('handle close')}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Add an Invoice using the form below.
                </DialogContentText>
                <TextField
                  margin="dense"
                  id="invoice_id"
                  name="invoice_id"
                  label="invoice_id"
                  type="number"
                  fullWidth
                  value={invoice.get('invoice_id')}
                  onChange={change}
                  disabled={mode === 'add'}
                />
                <FormControl fullWidth>
                  <InputLabel htmlFor="year">Year</InputLabel>
                  <Select
                    value={invoice.get('year') || new Date().getFullYear()}
                    name="year"
                    onChange={change}
                    input={<Input id="age-simple" />}

                    id='year'
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {getYearsList().map(year => {
                        return (
                        <MenuItem value={year}>
                          {year}
                        </MenuItem>
                        );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                <InputLabel htmlFor="client">Client</InputLabel>
                <Select
                  value={invoice.get('client') || ''}
                  name="client"
                  onChange={change}
                  input={<Input id="age-simple" />}
                  id="client"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Clients.models.map(client => <MenuItem key={client.id} value={client.id}>{client.get('name')}</MenuItem>)}
                </Select>
                </FormControl>
                <TextField
                  margin="dense"
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  value={invoice.get('description')}
                  onChange={change}
                />
                <TextField
                  margin="dense"
                  id="value"
                  name="value"
                  label="Value"
                  type="number"
                  fullWidth
                  value={invoice.get('value')}
                  onChange={change}
                />
                <TextField
                  margin="dense"
                  id="observations"
                  name="observations"
                  label="Observations"
                  type="text"
                  multiline
                  fullWidth
                  value={invoice.get('observations')}
                  rows="3"
                  onChange={change}
                />

                <h3>Dates</h3>

                <TextField
                  id="date"
                  label="Issued At"
                  type="date"
                  name="issued_at"
                  value={invoice.get('issued_at')}
                  onChange={change}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
                />
                <TextField
                  id="date"
                  label="Sent At"
                  type="date"
                  name="sent_at"
                  value={invoice.get('sent_at')}
                  onChange={change}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
                />
                <TextField
                  id="date"
                  label="Paid At"
                  type="date"
                  name="paid_at"
                  value={invoice.get('paid_at')}
                  onChange={change}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={submit} color="primary">
                  {action}
                </Button>
                <Button onClick={close} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
        );
    }

});




const InvoicesList = observer(class InvoicesList extends Component {

    filters = {
        page_size: 100,
        year: new Date().getFullYear()
    }

    state = {
        open: false,
        mode: 'add'
    }

    constructor(props) {
        super(props);
        this.invoices = new InvoicesCollection();
        this.invoice = this.invoices.build();
    }

    componentWillMount() {
        if (!authStore.user) {
            authStore.setRedirectUrl(this.props.location.pathname);
            this.props.history.push('/login');
        }
    }

    _refresh = () => {
        this.invoices.fetch(this.filters);
    }

    componentDidMount() {
        this._refresh();
    }

    handleInputChange = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.invoice.set({
            [name]: value
        });
    }

    handleSubmit = event => {
        this.invoice.save().then(() => this._refresh());
        this.setState({open: false});
    }

    handleDestroy = invoice => {
        invoice.destroy().then(this._refresh);
    }

    handleEdit = invoice => {
        this.invoice = invoice;
        this.setState({open: true, mode: 'edit'});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleAdd = event => {
        this.invoice = this.invoices.build();
        this.setState({open: true, mode: 'add'});
    }

    handlePaid = invoice => {
        invoice.set({state: 'PAID'});
        invoice.save().then(this._refresh);
    }

    handleIssue = invoice => {
        const body = encodeURIComponent(
            `Boa Tarde Rosa,\n
            Agradeço a emissão da seguinte fatura:\n
            Cliente: ${invoice.client.get('name')}
            NIF: ${invoice.client.get('nif')}
            Morada: ${invoice.client.get('address')}
            Código Postal: ${invoice.client.get('zip_code')}
            Descrição: ${invoice.get('description')}
            Valor: ${invoice.get('value')} euros (+IVA)
            Forma de Pagamento: Pronto\n
            cumps,
            Lobo`
        );
        const to = 'geral@rosaserra.pt,rosa@rosaserra.pt';
        const subject = 'Emissão Fatura';
        const mailto = "mailto:" + to + "?subject=" + subject + "&body=" + body;
        window.location.href = mailto;
        const today = moment().format('YYYY-MM-DD');
        invoice.set({issued_at: today});
        invoice.save().then(this._refresh);
    };

    handleSend = invoice => {
        const body = encodeURIComponent(invoice.client.get('client_account_email_template'));
        const to = invoice.client.get('client_account_emails');
        const subject = 'Envio Fatura';
        const mailto = "mailto:" + to + "?subject=" + subject + "&body=" + body;
        window.location.href = mailto;
        const today = moment().format('YYYY-MM-DD');
        invoice.set({sent_at: today});
        invoice.save().then(this._refresh);

    }

    handleFilter = year => {
        this.filters['year'] = year;
        this._refresh();
    }

    render() {
        if(this.invoices.request) {
            return <CircularProgress color="secondary" />;
        }
        return (
            <div>
              <YearFilter setfilter={this.handleFilter} year={this.filters.year}/>
              <Invoices invoices={this.invoices} page_size={this.filters.page_size} destroy={this.handleDestroy} edit={this.handleEdit} paid={this.handlePaid} issue={this.handleIssue} send={this.handleSend}/>
              <Button onClick={this.handleAdd}>Add Invoice</Button>
              <InvoiceForm mode={this.state.mode} open={this.state.open} close={this.handleClose} invoice={this.invoice} change={this.handleInputChange} submit={this.handleSubmit}/>
            </div>
        );
    }
});

export default InvoicesList;

import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import InvoicesList from "./components/InvoicesList/InvoicesList";
import ExpensesList from "./components/ExpensesList/ExpensesList";
import Login from "./components/AuthViews/Login";
import Register from "./components/AuthViews/Register";

class App extends Component {
    render() {
        return (
            <Router>
              <>
                <Route exact path="/" component={InvoicesList}/>
		<Route exact path="/expenses" component={ExpensesList}/>
		<Route exact path="/login" component={Login}/>
		<Route exact path="/register" component={Register}/>
              </>
            </Router>
        );
    }
}

export default App;

import React, {Component} from 'react';
import {observer} from 'mobx-react';
import './AuthView.css';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import AuthStore from "../../services/AuthStore";
import Grid from '@material-ui/core/Grid';
import styles from './Login.module.scss';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            errors: {}
        };
    }


    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : (target.type === 'date') ? new Date(target.value) : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = () => {
        AuthStore.login(this.state.email, this.state.password).then(
            resp => this.setState({errors: resp.data})
        );
    }

    componentWillReact() {
        if (AuthStore.user) {
            var rurl = '/';
            if (AuthStore.redirectUrl !== null && AuthStore.redirectUrl !== 'null') {
                rurl = AuthStore.redirectUrl;
                AuthStore.setRedirectUrl(null);
            }
            this.props.history.push(rurl);
        }
    }

    _getErrorMsg(field) {
        try {
            return this.state.errors[field];
        } catch (e) {
            return false;
        }
    }

    render() {
        /* eslint-disable no-unused-expressions */
        AuthStore.user

        return (
            <Grid container direction="row" justify="center">
              <Card className={styles.Login}>
                <CardContent>

                  <h1>Login</h1>

                  <Grid container direction="column">

                    <TextField
                      name="email"
                      label="Email"
                      value={this.state.email}
                      margin='dense'
                      type='email'
                      onChange={this.handleInputChange}
                      // error={this._getErrorMsg('email')}
                      helperText={this._getErrorMsg('email')}
                    />

                    <TextField
                      name="password"
                      label={'Password'}
                      value={this.state.password}
                      margin='dense'
                      type='password'
                      onChange={this.handleInputChange}
                      // error={this._getErrorMsg('password')}
                      helperText={this._getErrorMsg('password')}
                    />

                    <Button color='primary' variant='contained' className='submit-btn primary-btn'
                            onClick={this.handleSubmit}>
                      {'Sign in'}
                    </Button>
                    {this._getErrorMsg('non_field_errors') &&
                     <small style={{color: 'red', textAlign: 'center', width: '100%', paddingTop: 5}}>
                       {this._getErrorMsg('non_field_errors')}
                     </small>
                    }
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
        );
    }

}

export default observer(Login);

import {Model} from './base/Model';
import Collection from "./base/Collection";
import {decorate, computed} from 'mobx';
import {Organization} from './OrganizationsStore';

export class Invoice extends Model {
    urlRoot() {
        return "/invoices/";
    }

    get client() {
    	const data = new Organization({id: this.get('client')})
        data.fetch()
        return data
    }
}

decorate(Invoice, {
    client: computed
})

export class Invoices extends Collection {

    urlRoot() {
        return "/invoices/";
    }

    model() {
        return Invoice;
    }
}
